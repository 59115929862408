<template>
  <div class="blogs">
    <tabBar :cardIndex="1" :activeIndexsa="0" class="tabberheid" />
    <!-- <div class="lsittabbers">
      <div class="headers">
				<div class="headss">
					<div
					  v-for="(item, index) in list"
					  :key="index"
					  class="tips"
					  :class="filg == index ? 'active_tip' : ''"
					  @click="qies(index, item.ids)"
					>
					<img v-if="filg==index" :src='item.imgs' />
					<img v-else :src='item.img' />
					  <span>{{ item.name }}</span>
					</div>
				</div>
      </div>
    </div> -->
    <div class="blogdetail">
      <img class="detailbg" :src="obgdetail.img" alt="" />
      <div class="detailcenter">
        <div class="title">
          {{ obgdetail.title }}
        </div>
        <div class="carddate">
          <div class="left">
            <img class="heder_img" src="../assets/img/touxing101.png" alt="" />
            <span class="topsapn">美国皓齿</span>
          </div>
          <div class="right">{{ obgdetail.updateTime }}</div>
        </div>
        <div v-html="obgdetail.content"></div>
        <div class="libottom">
          <div class="btncard">
            <img class="imgs" src="../assets/img/biaoqian5627.png" alt="" />
            {{ obgdetail.labelName }}
          </div>
          <div class="caopzuo">
            <div class="allcss fenxiang" @click="share">
              <img class="imgs" src="../assets/img/fenxiang.png" alt="" />分享
            </div>
            <div class="allcss liulan">{{ obgdetail.viewNum }}浏览</div>
            <div class="allcss pinglun" @click="tofabus">
              <img class="imgs" src="../assets/img/xie/pinglun.png" alt="" />{{
                obgdetail.replyNum
              }}
            </div>
            <div class="allcss dainzan" @click="dianzans(!obgdetail.isDoUp)">
              <img
                class="imgs"
                src="../assets/img/4509.png"
                alt=""
                v-if="obgdetail.isDoUp"
              />
              <img
                class="imgs"
                src="../assets/img/xie/zan.png"
                alt=""
                v-if="!obgdetail.isDoUp"
              />
              <span :style="obgdetail.isDoUp ? 'color:#f26034' : ''">{{
                obgdetail.giveNum
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="comment">
      <div class="commenttop">
        全部评论 <span>{{ obgdetail.replyNum }}</span>
      </div>
      <div class="container">
        <div
          class="content_item"
          v-for="(item, index) in blogsList"
          :key="index"
          v-show="index < num"
        >
          <ul class="display_flex flex-direction_column">
            <div class="centerstop">
              <img class="touxiang" :src="item.headImg" v-if="item.headImg" />
              <img class="touxiang" src="@/assets/img/user.png" v-else />
              <div class="pinglun_right">
                <div class="tops">{{ item.nickName }}</div>
                <div class="textcens">{{ item.content }}</div>
                <div class="dates">
                  <span>{{ item.createTime }}</span>
                  <span @click="huickick(item.id, item.nickName)" style="cursor: pointer;">
                    <img
                      class="clahuifu"
                      src="../assets/img/xie/huifu.png"
                      alt=""
                    />
                    回复
                  </span>
                </div>
                <div class="commentright">
                  <div
                    class="commentlistcent"
                    v-for="(ims, idx) in item.forumReplieChild"
                    :key="idx"
                    v-show="idx < item.num"
                  >
                    <div class="commentlistcentcenter">
                      <img
                        class="minimg"
                        :src="ims.headImg"
                        v-if="ims.headImg"
                      />
                      <img class="minimg" src="@/assets/img/user.png" v-else />
                      <div class="minright">
                        <div class="mintops">
                          {{ ims.nickName }}
                          <span v-if="ims.pid != item.id"
                            >回复 {{ ims.nickNameTwo }}</span
                          >
                        </div>
                        <div class="mintextcens">{{ ims.content }}</div>
                        <div class="mindates">
                          <span>{{ ims.createTime }}</span>
                          <span @click="huickick(ims.id, ims.nickName)" style="cursor: pointer;">
                            <img
                              class="clahuifu"
                              src="../assets/img/xie/huifu.png"
                              alt=""
                            />
                            回复
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="allltextstwoa"
                    v-if="item.forumReplieChild.length > 2"
                    @click="showMore(index)"
                  >
                    {{ item.txt }}
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>
      <div class="allltexts" @click="showMore" v-if="blogsList.length > 2">
        {{ txt }}
      </div>
    </div>
    <div class="pinglint">
      <div @click="tofabus">
        <input
          class="inputs"
          placeholder="请发表你的评论"
          :disabled="true"
          type="text"
        />
      </div>
      <div class="btns" @click="tofabus">发布</div>
    </div>
    <el-dialog
      :title="pintext"
      :visible.sync="dialogVisible"
      :show-close="false"
      width="30%"
    >
      <div>
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
          placeholder="请输入内容"
          v-model="content"
        >
        </el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="removedialogVisible">取 消</el-button>
        <el-button type="primary" @click="dialogVisibleOkes">确 定</el-button>
      </div>
    </el-dialog>
    <footers></footers>
    <div class="lsitdas">
      <div class="imgsa"><img @click="share" src="../assets/img/xie/fen18.png" alt="" /></div>
      <div class="imgsa"><img @click="tofabus" src="../assets/img/xie/fen19.png" alt="" /></div>
      <div class="imgsa"><img v-if="!obgdetail.isDoUp" @click="dianzans(!obgdetail.isDoUp)" src="../assets/img/xie/fen20.png" alt="" />
      <img v-if="obgdetail.isDoUp" @click="dianzans(!obgdetail.isDoUp)" src="../assets/img/xie/fen22.png" alt="" />
      </div>
      <div class="imgsa"><img @click="huishoudingbu" src="../assets/img/xie/fen21.png" alt="" /></div>
    </div>
  </div>
</template>

<script>
import tabBar from "@/components/tabBar.vue";
import footers from "@/components/footer.vue";
import {
  getforumdetail,
  postforumReply,
  putaddUp,
  forumread,
  forumshard,
} from "../request/homeApi";
export default {
  components: {
    tabBar,
    footers,
  },
  data() {
    return {
      dialogVisible: false,
      content: "",
      ints: "",
      filg: 0,
      pintext: "评论",
      list: [
        {
          name: "博客",
          img: require("../assets/img/xie/boke.png"),
          imgs: require("../assets/img/xie/boke2.png"),
        },
        {
          name: "论坛",
          img: require("../assets/img/xie/luntan2.png"),
          imgs: require("../assets/img/xie/luntan.png"),
        },
      ],
      num: 2, // 默认最多显示的个数；最大行数*每行显示的个数 Number
      isShow: true,
      txt: "展示全部评论",
      blogsList: [],
      id: "",
      pingid: "", //回复的评论id
      obgdetail: {},
      arrList: [],
      tokens: "",
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.detailsobg();
    this.forumreadApi();
    if (localStorage.getItem("token")) {
      this.tokens = localStorage.getItem("token");
    }
  },
  methods: {
    forumreadApi() {
      forumread(this.id)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            //this.detailsobg();
          }
        })
        .catch((res) => {
          console.log("网络开小差了，没有获取到图片验证码哦");
        });
    },
    huishoudingbu(){
      window.scrollTo(0, 0)
    },
    //分享
    share() {
      if (!this.tokens) {
        this.$message.error("请登录之后再操作");
        return false;
      }
      this.$copyText(window.location.href).then(
        (res) => {
          forumshard(this.id)
            .then((res) => {
              console.log(res);
              if (res.code == 200) {
                //this.detailsobg();
              }
            })
            .catch((res) => {
              console.log("网络开小差了，没有获取到图片验证码哦");
            });
          this.$message.success("复制成功");
        },
        (error) => {
          this.$message.success("复制失败");
        }
      );
    },
    // 回复评论
    huickick(id, name) {
      if (!this.tokens) {
        this.$message.error("请登录之后再操作");
        return false;
      }
      this.pingid = id;
      this.dialogVisible = true;
      this.pintext = "回复 " + name + "的评论";
    },
    // 发布评论
    tofabus() {
      if (!this.tokens) {
        this.$message.error("请登录之后再操作");
        return false;
      }
      this.dialogVisible = true;
      this.pingid = "";
      this.pintext = "评论";
    },
    removedialogVisible() {
      this.dialogVisible = false;
      this.content = "";
      this.pingid = "";
    },
    dialogVisibleOkes() {
      let data = {};
      if (this.pingid) {
        data = {
          pid: this.pingid,
          content: this.content,
          forumId: this.id,
        };
      } else {
        data = {
          forumId: this.id,
          content: this.content,
        };
      }

      postforumReply(data)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.content = "";
            this.pingid = "";
            this.dialogVisible = false;
            this.detailsobg();
          }
        })
        .catch((res) => {
          console.log("网络开小差了，没有获取到图片验证码哦");
        });
    },
    // 点赞
    dianzans(isdianzan) {
      if (!this.tokens) {
        this.$message.error("请登录之后再操作");
        return false;
      }
      putaddUp(this.id, isdianzan)
        .then((res) => {
          if (res.code == 200) {
            this.detailsobg();
            this.$message.success("操作成功！");
          }
        })
        .catch((res) => {
          console.log("网络开小差了，没有获取到图片验证码哦");
        });
    },
    // 获取详情
    detailsobg() {
      getforumdetail(this.id)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.obgdetail = res.result;

            res.result.forumReplies.forEach((item) => {
              item.isShow = true;
              item.num = 2;
              item.txt = "展开全部回复";
              if (item.forumReplieChild.length > 0) {
                this.arrListpush(item.forumReplieChild, item.id, item.nickName);
              }
            });
            this.blogsList = res.result.forumReplies;
            this.blogsList.forEach((its) => {
              this.arrList.forEach((ims) => {
                if (its.id == ims.maxid && its.id != ims.pid) {
                  its.forumReplieChild.push(ims);
                }
              });
            });
          }
        })
        .catch((res) => {
          console.log("网络开小差了，没有获取到图片验证码哦");
        });
    },
    arrListpush(arrays, id, nickName) {
      arrays.forEach((item) => {
        this.arrList.push(item);
        item.nickNameTwo = nickName;
        item.maxid = id;
        if (item.forumReplieChild.length > 0) {
          this.arrListpush(item.forumReplieChild, id, item.nickName);
        }
      });
    },
    showMore(index) {
      console.log("1-----------", index);

      if (index < this.blogsList.length) {
        this.$nextTick(() => {
          this.blogsList[index].isShow = !this.blogsList[index].isShow;
          this.blogsList[index].num = this.blogsList[index].isShow
            ? 2
            : this.blogsList[index].forumReplieChild.length;
          this.blogsList[index].txt = this.blogsList[index].isShow
            ? "展开全部回复"
            : "收起回复";
        });
        this.$forceUpdate();
        console.log(this.blogsList);
      } else {
        this.isShow = !this.isShow;
        this.num = this.isShow ? 2 : this.blogsList.length;
        this.txt = this.isShow ? "展示全部评论" : "收起评论";
      }
    },
    //切换
    qies(ind, ids) {
      if (ind == 0) {
        this.$router.push({ path: "compare" });
      }
      if (ind == 1) {
        this.$router.push({ path: "forumlist" });
      }
      this.$nextTick(() => {
        this.filg = ind;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tabberheid {
  height: 80px;
}
.lsittabbers {
  height: 85px;
}
.headers {
  width: 100%;
  height: 85px;
  background-color: #ededed;
  color: #535353;
  font-size: 16px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 99;
  left: 0;
  top: 80px;
  .headss {
    margin-left: 80px;
    height: 100%;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
  }
  .tips {
    margin-left: 95px;
    cursor: pointer;
    display: flex;
    flex-flow: column;
    justify-content: center;
    img {
      width: 33px;
      height: 31px;
    }
  }
  .active_tip {
    color: #00a3e0;
    // border-bottom: 4px solid #0075AA;
  }
}
.blogs {
  background: #f7f7f7;
  width: 100%;
  //   height: 100%;
  .blogdetail {
    width: 690px;
    margin: 0 auto;
    background: #ffffff;
    margin-top: 50px;
    .detailbg {
      width: 100%;
    }
    .detailcenter {
      padding: 20px 26px;
      .title {
        font-size: 24px;
        color: #535353;
      }
      .carddate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 22px 0;
        .left {
          .heder_img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
          .topsapn {
            margin-left: 12px;
            font-size: 16px;
            color: #535353;
          }
        }
        .right {
          font-size: 14px;
          color: #535353;
        }
      }
      .libottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 30px 0px 0px;
        .btncard {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 4px 16px;
          cursor: pointer;
          // line-height: 30px;
          background: #f0f9ff;
          border-radius: 15px;
          color: #00a3e0;
          .imgs {
            width: 12px;
            height: 12px;
            margin-right: 4px;
          }
        }
        .caopzuo {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .allcss {
            font-size: 16px;
            color: #999999;
            cursor: pointer;
            display: flex;
            align-items: center;
            .imgs {
              width: 19px;
              height: 18px;
              margin-right: 5px;
            }
          }
          .pinglun {
            margin: 0 30px;
          }
          .liulan {
            margin-left: 30px;
          }
        }
      }
    }
  }
}
.comment {
  width: 690px;
  margin: 0 auto;
  background: #ffffff;
  margin-top: 14px;
  padding: 0 25px;
  overflow: hidden;
  .commenttop {
    margin-top: 20px;
    font-size: 16px;
    color: #535353;
  }
  .container {
    width: 100%;
    margin: 0;
    padding: 0;
    margin-top: 22px;
    .content_item {
      .display_flex {
        padding: 0;
        margin: 0;
        .centerstop {
          display: flex;
          justify-content: flex-start;
          .touxiang {
            width: 36px;
            height: 36px;
            border-radius: 50%;
          }
          .pinglun_right {
            width: 100%;
            margin-left: 10px;
            .tops {
              // padding: 10px 0;
              margin-top: 6px;
              font-size: 16px;
              color: #535353;
            }
            .textcens {
              margin-top: 7px;
              font-size: 16px;
              color: #535353;
            }
            .dates {
              margin-top: 14px;
              font-size: 12px;
              color: #999999;
              margin-bottom: 10px;
              display: flex;
              align-items: center;
              .clahuifu {
                width: 12px;
                height: 12px;
                margin-right: 5px;
                margin-left: 25px;
              }
            }
            .commentright {
              background: #f6f6f6;
              .commentlistcent {
                background: #f6f6f6;
                padding: 13px;
                .commentlistcentcenter {
                  display: flex;
                  justify-content: flex-start;
                  border-bottom: 1px solid #e2e2e2;
                  padding-bottom: 18px;
                  .minimg {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    margin-top: 13px;
                  }
                  .minright {
                    margin-left: 12px;
                    .mintops {
                      padding-top: 19px;
                      font-size: 14px;
                      color: #535353;
                    }
                    .mintextcens {
                      padding-top: 10px;
                      font-size: 14px;
                      color: #535353;
                    }
                    .mindates {
                      padding-top: 13px;
                      margin-top: 14px;
                      font-size: 14px;
                      color: #999999;
                      display: flex;
                      align-items: center;
                      .clahuifu {
                        width: 12px;
                        height: 12px;
                        margin-right: 5px;
                        margin-left: 25px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.allltexts {
  width: 100%;
  padding: 20px 0;
  text-align: center;
  font-size: 16px;
  color: #00a3e0;
  cursor: pointer;
}
.allltextstwoa {
  width: 100%;
  padding: 20px 20px;
  font-size: 16px;
  color: #00a3e0;
  cursor: pointer;
}
.pinglint {
  width: 690px;
  height: 56px;
  margin: 0 auto;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1px;
  margin-bottom: 33px;
  .inputs {
    width: 554px;
    height: 36px;
    padding: 10px 13px;
    background: #ffffff;
    border: 1px solid #e2e2e2;
    border-radius: 2px;
    font-size: 14px;
    color: #999999;
  }
  .btns {
    width: 78px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    background: #00a3e0;
    border-radius: 2px;
    font-size: 14px;
    color: #ffffff;
    margin-left: 6px;
    cursor: pointer;
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
}
.lsitdas {
  position: fixed;
  right: 3%;
  bottom: 20%;
  .imgsa{
    cursor: pointer;
    margin: 10px 0;
  }
}
</style>